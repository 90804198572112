const setPlacementClass = {
  methods: {
    setPlacementClass(type) {
      switch (type) {
        case 1:
          // GRP fixed
          return 'media-plan-pt-GRP media-plan-pt';
        case 2:
          // Time fixed
          return 'media-plan-pt-fixed media-plan-pt';
        case 3:
          // Automatic
          return 'media-plan-pt-automatic media-plan-pt';
        case 4:
          // Low priority
          return 'media-plan-pt-low media-plan-pt';
        case 5:
          //Cross channel
          return 'media-plan-pt-cross media-plan-pt';
        default:
          return 'media-plan-pt';
      }
    },
  },
};

export default setPlacementClass;
