<template>
  <div class="mt-3 d-flex">
    <table class="table-budget">
      <tr>
        <th>{{ $t('table.commitment') }}</th>
        <td>
          <template v-if="entity">
            {{ +entity.commitment_budget | toFixedAndSpace }}
          </template>
        </td>
      </tr>
      <tr>
        <th>{{ $t('table.plan') }}</th>
        <td>
          <template v-if="entity">
            {{ +entity.plan_budget | toFixedAndSpace }}
          </template>
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('table.fact') }}
        </th>
        <td>
          <template v-if="entity">
            {{ +entity.fact_budget | toFixedAndSpace }}
          </template>
        </td>
      </tr>
      <tr>
        <th>{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
        <td>
          <template v-if="entity">
            {{ +entity.plan_fact_budget | toFixedAndSpace }}
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import toFixedAndSpace from '@/filters/toFixedAndSpace';

export default {
  filters: { toFixedAndSpace },
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
table.table-budget
  font-size: min(14px, 1rem)
  text-align: right

  th:first-child
    padding-right: 0.5rem
</style>