/**
 * Debounce utility helper. Calls given function after set delay
 * @param {Function} fn Given function
 * @param {Number} [delay=1500] Delay after which the given function will be called
 * @returns
 */
export default function debounce(fn, delay = 1500) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer); // clear any pre-existing timer
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, delay);
  };
}
