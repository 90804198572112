const downloadFileHandler = {
  methods: {
    parseFileName(str) {
      if (str.match(/filename\*=utf-8/gi)) {
        str = str.match(/filename\*=utf-8'{0,2}(.*?\..*?$)/gi)[0].replace(/filename\*=utf-8''/gi, '');
        return (str = decodeURIComponent(str));
      } else {
        return (str = str.match(/filename="?(.*?\..*?)"?$/gi)[0].replace(/filename="?(.*?\..*?)"?$/gi, '$1'));
      }
    },

    downloadFile(headerLine, url) {
      let filename = this.parseFileName(headerLine);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(link.href);
        link.remove();
      }, 4e4);
    },

    prepareAndDownloadFile(res, csvExport = false) {
      let headerLine = res.headers['content-disposition'];
      let url;
      if (!csvExport) url = URL.createObjectURL(new Blob([res.data]));
      else url = 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + res.data); // specific for text files to save UTF-8 cyrillic symbols
      this.downloadFile(headerLine, url);
    },
  },
};

export default downloadFileHandler;
