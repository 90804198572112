const getMinOrMaxDate = {
  methods: {
    // 'YYYY-MM-DD'[] => 'YYYY-MM-DD'
    getMinDate(...dates) {
      // get minimum date from array of date strings 'YYYY-MM-DD'
      if (!dates || dates.length === 0) return undefined;
      const filteredData = dates.filter((d) => d && /20[0-9][0-9]-[0-1][0-9]-[0-3][0-9]/.test(d));
      const minDateMs = filteredData.length > 0 ? Math.min(...filteredData.map((str) => new Date(str))) : undefined;
      return minDateMs ? new Date(minDateMs).toISOString().slice(0, 10) : undefined;
    },
    getMaxDate(...dates) {
      // get minimum date from array of date strings 'YYYY-MM-DD'
      if (!dates || dates.length === 0) return undefined;
      const filteredData = dates.filter((d) => d && /20[0-9][0-9]-[0-1][0-9]-[0-3][0-9]/.test(d));
      const maxDateMs = filteredData.length > 0 ? Math.max(...filteredData.map((str) => new Date(str))) : undefined;
      return maxDateMs ? new Date(maxDateMs).toISOString().slice(0, 10) : undefined;
    },
  },
};

export default getMinOrMaxDate;
